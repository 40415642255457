












































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Dispatching from '../../../../store/models/Dispatching'

interface ComponentData {
  loading: boolean
  refreshTimer: number | null
  columns: Record<string, any>[]
  actions: Array<{ text: string; value: string }>
}

export default Vue.extend({
  data(): ComponentData {
    return {
      loading: false,
      refreshTimer: null,
      columns: [
        {
          label: 'N°',
          field: '_iteration'
        },
        {
          label: 'Libellé',
          field: 'creditLine.name',
          searchable: true
        },
        {
          label: 'Effectif Total',
          field: 'dispatchingFile.nbBeneficiaries',
          align: 'center'
        },
        {
          label: 'Montant Total',
          field: '_totalAmount'
        },
        {
          label: 'Agence Responsable',
          field: 'agency'
        },
        {
          label: 'Etat',
          field: 'state'
        },
        {
          label: 'Action',
          field: '_action',
          align: 'right'
        }
      ],
      actions: [
        { text: 'Voir details', value: 'details' },
        { text: 'Annuler', value: 'cancel' },
        { text: 'Valider', value: 'valider' }
      ]
    }
  },

  computed: {
    ...mapGetters('product', ['getCurrentProduct']),
    dispatchings() {
      return Dispatching.query()
        .with(['creditLine', 'dispatchingFile'])
        .orderBy('timestamp', 'desc')
        .where(
          (value: any) =>
            value !== 'canceled' && value.productId == this.getCurrentProduct.id
        )
        .all()
    }
  },

  created() {
    this.fetchDispatchings()
  },

  mounted() {
    if (!this.refreshTimer) {
      // TODO: maybe websocket
      this.refreshTimer = setInterval(() => {
        this.fetchDispatchings()
      }, 5000)
    }
  },

  beforeDestroy() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
      this.refreshTimer = null
    }
  },

  methods: {
    fetchDispatchings() {
      if (!this.refreshTimer) {
        this.loading = true
      }

      Dispatching.api()
        .fetch({
          include: [
            'creditLine',
            'dispatchingFile.totalAmount',
            'dispatchingFile.nbBeneficiaries'
          ],
          'state!': 'canceled'
        })
        .then((r: any) => {
          console.log('>', r)
          this.loading = false
        })
    },

    onSeeDetails(dispatchingId: string): void {
      this.$router.push({
        name: 'dispatchings.show',
        params: { dispatchingId }
      })
    }
  }
})
