var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-box',[_c('div',{staticClass:"container px-10 py-10 mx-auto"},[_c('v-data-table',{attrs:{"paginated":"","hide-add-action":"","title":"Liste des dispatchings","columns":_vm.columns,"items":_vm.dispatchings,"loading":_vm.loading},scopedSlots:_vm._u([{key:"_iteration",fn:function(ref){
var iteration = ref.iteration;
return [_vm._v(" "+_vm._s(iteration)+" ")]}},{key:"_totalAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.dispatchingFile.totalAmount))+" ")]}},{key:"state",fn:function(ref){
var dispatching = ref.item;
return [(dispatching.state === 'canceled')?_c('v-label',{attrs:{"color":"red"}},[_vm._v(" Annulé ")]):_vm._e(),(dispatching.state === 'created')?_c('v-label',{attrs:{"color":"orange"}},[_vm._v(" En attente de validation ")]):(dispatching.state === 'pending')?_c('v-label',[_vm._v(" En cours ")]):(dispatching.state === 'completed')?_c('v-label',{attrs:{"color":"green"}},[_vm._v(" Terminé ")]):_vm._e()]}},{key:"_action",fn:function(ref){
var dispatching = ref.item;
return [_c('v-fnm-popover',{ref:"popover",attrs:{"rounded":"","shadow":"","bg":"white","mt":"1","overflow-y":"auto","max-height":"300px","right":"0","w":"56"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var on = ref.on;
return [_c('v-button',_vm._g({attrs:{"small":"","color":"gray-500"}},on),[_vm._v(" Actions "),_c('v-icon',{attrs:{"icon":"chevron-down"}})],1)]}}],null,true)},[_c('v-menu',[_c('v-menu-item',{on:{"click":function($event){return _vm.$router.push({
                  name: 'dispatchings.show',
                  params: { dispatchingId: dispatching.id }
                })}}},[_vm._v(" Voir détails ")]),(dispatching.state === 'created' && _vm.$granted('ceo'))?[_c('v-menu-item',{on:{"click":function($event){return _vm.$router.push({
                    name: 'dispatchings.summary',
                    params: { dispatchingId: dispatching.id }
                  })}}},[_vm._v(" Valider le dispatching ")]),_c('v-menu-item',{on:{"click":function($event){return _vm.$router.push({
                    name: 'dispatchings.summary',
                    params: { dispatchingId: dispatching.id }
                  })}}},[_vm._v(" Annuler le dispatching ")])]:_c('v-menu-item',{on:{"click":function($event){return _vm.$router.push({
                  name: 'dispatchings.summary',
                  params: { dispatchingId: dispatching.id }
                })}}},[_vm._v(" Voir le rapport ")])],2)],1)]}},{key:"table-footer",fn:function(){return [_c('tr',[_c('td',{staticClass:"py-10",attrs:{"colspan":"7"}})])]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }